import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const withAnalyticsTracking = (WrappedComponent) => {
  const trackPage = () => {
    const page = window.location.pathname + window.location.search;
    ReactGA.pageview(page);
  };

  const ComponentWithAnalytics = (props) => {
    useEffect(() => {
      trackPage();
    }, []);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAnalytics;
};

export default withAnalyticsTracking;

import React, {useState, useEffect} from 'react';
import { Typography, Row, Col } from 'antd';

import "./Terms.css"

const { Title, Paragraph } = Typography;

function TermsPage() {
    const nation = localStorage.getItem("nation");

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div className="container terms-container">
        { (nation === "中国") ? (
            <div>
                <Row>
                    <Title level={2}>服务条款</Title>
                </Row>
                <Row>
                    <Paragraph>更新时间: 2023-05-02</Paragraph>
                </Row>
                <Row>
                    <Col span={24}>
                        <Title style={{textDecoration: 'underline'}} level={4}>1. 简介</Title>
                    </Col>
                    <Col span={24}>
                        <Paragraph style={{fontSize: "18px"}}>
                            感谢您信任并使用 AIdeaRoom 设计您的新房间！
                            本《服务条款》（以下简称“条款”）管理您对 AIdeaRoom 的访问和使用，包括使用网站提供的任何内容、功能和服务（统称为“服务”）。
                            通过访问或使用我们的服务，您同意受这些服务条款的约束。我们建议您在使用我们的服务之前仔细阅读这些服务条款。
                            当您使用本网站提供的服务时，则表示您同意遵守所有条款。
                        </Paragraph>
                        <Paragraph style={{fontSize: "18px"}}>
                            本服务利用人工智能和生成模型为用户提供室内设计的创意和建议。用户可以通过与网页应用界面进行互动，获得设计建议。用户也可以通过不同的设置，获得多种设计结果。
                        </Paragraph>
                    </Col>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>2. 用户帐号</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                            为了使用本服务，您需要创建一个账户。您同意在创建账户时提供准确、完整和当前的信息，并在必要时更新您的信息。
                            您有责任保持账户凭证的机密性，并对在您的账户下发生的所有活动负责。您同意立即通知我们任何未经授权使用您的账户或其他安全漏洞的情况。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>3. 知识产权</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们的网页应用通过模型生成的图像是我们公司的财产，并受到知识产权法的保护。我们向您授予非独占性、不可转让、免版税的许可，用于个人或商业用途使用、复制和修改人工智能生成的图像，但需遵守以下条件：<br/>
                                1）您确认并同意我们公司保留所有对人工智能生成的图像的权利、所有权和利益，包括所有知识产权。<br/>
                                2）您对您如何使用人工智能生成的图像负全部责任，并同意在使用中遵守所有适用的法律、法规和行业标准。<br/>
                                3）您同意不以任何侵犯他人知识产权或违反任何适用的法律或法规的方式使用人工智能生成的图像。<br/>
                                4）您同意赔偿、捍卫并使我们免受因您使用人工智能生成的图像而产生的任何索赔、损害、损失或费用。<br/>
                                5）对于您使用人工智能生成的图像造成的任何损害，包括但不限于直接、间接、附带或结果性损害，我们不承担任何责任。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>4. 用户内容</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                您完全对您提交或传输到本服务的任何内容负责，包括但不限于图片、信息、反馈和设计。您声明并保证您具有提交或传输此类内容的所有必要权利，并且它不违反任何适用的法律或侵犯任何第三方权利。<br/>
                                通过向本服务提交或传输内容，您授予我们非独占性、免费、永久、不可撤销和完全可转让的权利，允许在任何媒体中使用、复制、修改、适应、发布、翻译、创作衍生作品、分发和展示此类内容，覆盖全球范围。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>5. 免责声明和责任限制</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                本服务对于生成的内容、不提供任何明示或暗示的保证，包括但不限于适销性、特定用途适用性或非侵权性的保证。我们不对服务生成的内容和用户内容的准确性或完整性提供任何保证或陈述。<br/>
                                在任何情况下，我们或我们的许可方对因使用本服务而导致的间接、特殊、附带、后果性或惩罚性损害不承担责任，包括但不限于利润损失、数据损失或业务中断，无论是基于保证、合同、侵权还是其他任何法律理论，即使我们已被告知此类损害的可能性。<br/>
                                本服务的某些内容源于互联网，作者保留所有权利，若涉及侵权，请以书面形式通知本公司，本公司会立刻删除所有相关内容。

                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>6. 赔偿</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                您同意保护、赔偿和使我们及我们的高管、董事、员工和代理人免受任何由于您使用本服务或违反本条款而引起的索赔、责任、损害、损失和费用的侵害。
                    您同意承担我们因您使用本服务而导致的任何第三方索赔、责任、损害、损失和费用，包括但不限于合理的律师费和成本。您同意对我们及我们的关联公司、合作伙伴、许可人和供应商进行赔偿，并保证在索赔过程中提供必要的合理协助和支持。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>7. 条款的修改</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们保留随时修改本条款的权利，通过在服务上发布修订后的条款来进行修改。在修订条款发布后，您继续使用本服务即表示您接受修订后的条款。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>8. 终止</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们保留随时终止或暂停您对本服务的全部或部分访问权利的权利，无论出于何种原因，而无需提前通知或承担任何责任。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>9. 其他</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                本条款构成您与我们之间关于本服务的完整协议，并取代所有先前或同时进行的口头或书面沟通和提案。
                如果本条款的任何条款被认定为无效或不可执行，其余条款仍然有效。我们未能执行本条款中的任何权利或条款不应被视为放弃该权利或条款。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>10. 用户责任</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                               用户在使用服务时有责任遵守当地法律和国际公约。用户在使用服务时必须严格遵守本声明，并且不得通过服务从事任何非法或不适当的活动。用户不得发布、传输或分享包含以下内容的信息：<br/>
                                1）违反宪法确立的基本原则。<br/>
                                2）危害国家安全，泄露国家机密，颠覆国家权力或破坏国家统一。<br/>
                                3）损害国家荣誉和利益。<br/>
                                4）煽动民族仇恨、歧视或破坏民族团结。<br/>
                                5）破坏国家宗教政策，宣传邪教或封建迷信。<br/>
                                6）散布谣言，扰乱社会秩序或破坏社会稳定。<br/>
                                7）传播淫秽、色情、赌博、暴力、凶杀、恐怖或犯罪煽动内容。<br/>
                                8）侮辱、诽谤或侵犯他人合法权益。<br/>
                                9）包含虚假、欺诈、有害、强制、侵犯隐私、骚扰、诽谤、粗俗、淫秽或其他具有道德冒犯性的内容。<br/>
                                10）包含受中国或您所居住国家管辖下的法律、法规、规则或准则限制或禁止的内容。您应进行必要的审查，确保您使用服务的行为符合适用的法律和法规，并承担由此产生的所有责任。您承认并同意，我们对于与服务或您使用服务相关的上述规定的任何违反不承担责任。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>11. 退款政策</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                如果由于我们无法控制的原因而无法提供服务，您可以提出赔偿申请。收到您的申请后，我们将进行审核，如果获得批准，我们将在7个工作日内处理您的赔偿申请。
                                赔偿金额将根据实际情况进行计算，并以不超过最大赔偿金额的能量值或退款形式提供。如果选择退款，退款将在退款流程完成后返还到您的付款账户。请注意，退款金额可能根据付款渠道而有所不同，并可能需要支付一定的费用。
                                请注意，如果您已经使用了我们的服务，我们将无法提供全额退款。我们将根据您已经消耗的能量值来计算应退还的金额。
                                如果您违反了我们的服务条款或任何其他相关政策，您的退款申请将不会被处理。
                                如果您有任何问题或疑虑，请随时与我们联系，我们将尽力协助您解决问题。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>12. 适用法律和管辖权</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                本条款受中华人民共和国法律的管辖。通过使用我们的网站，您同意任何由您使用我们网站引起的争议将受中华人民共和国法院的管辖。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>13. 联系我们</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                如果您对本网站有任何功能要求、评价或反馈，请随时通过微信公众号留言，我们会及时与您联系跟进。
                                我们团队也非常欢迎有兴趣的朋友一起交流。
                            </Paragraph>
                        </Col>
                    </Row>
                </Row>
            </div>
        ) : (
            <div style={{padding: "0 15%"}}>
              <Row>
                    <Title level={2}>Terms of service</Title>
                </Row>
                <Row>
                    <Paragraph>Last updated time: 2023-05-02</Paragraph>
                </Row>
                <Row>
                    <Col span={24}>
                        <Title style={{textDecoration: 'underline'}} level={4}>1. Introduction</Title>
                    </Col>
                    <Col span={24}>
                        <Paragraph style={{fontSize: "18px"}}>
                            Thank you for using AIdeaRoom to design your new room!
                            These terms of service ("Terms") govern your access to and use of AIdeaRoom, including any content,
                            functionality, and services offered on or through the website (collectively, the "Service").
                            By accessing or using our services, you agree to be bound by these terms of service.  We encourage you to read these terms of service carefully before using our services.
                            When you use the services provided by the Website, you agree to all terms.
                        </Paragraph>
                        <Paragraph style={{fontSize: "18px"}}>
                            The Service uses artificial intelligence and generation models to provide interior design ideas
                            and suggestions to users. Users can interact with the web application interface to request
                            design suggestions, provide feedback, and receive editing result.
                        </Paragraph>
                    </Col>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>2. User Accounts</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                To use the Service, you need to create an account. You agree to provide accurate, complete,
                                and current information when creating your account and to update your information as necessary.
                                You are responsible for maintaining the confidentiality of your account credentials and for all
                                activities that occur under your account. You agree to notify us immediately of any unauthorized
                                use of your account or any other breach of security.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>3. Intellectual Property</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                The AI-generated images produced by our web application are the property of our company and are
                                protected by intellectual property laws. We grant you a non-exclusive, non-transferable, royalty-free
                                license to use, reproduce, and modify the AI-generated images for personal or commercial use,
                                subject to the following conditions: <br/>
                                1) You acknowledge and agree that our company retains all rights, title, and interest in and to the AI-generated images, including all intellectual property rights.
                                <br/>
                                2) You are solely responsible for the use of the AI-generated images and agree to use them in compliance with all applicable laws, regulations, and industry standards.
                                <br/>
                                3) You agree not to use the AI-generated images in any way that may infringe on the intellectual property rights of others or violate any applicable laws or regulations.
                                <br/>
                                4) You agree to indemnify, defend, and hold us harmless from any claims, damages, losses, or expenses arising from your use of the AI-generated images.
                                <br/>
                                5) We are not responsible for any damages, including but not limited to direct, indirect, incidental, or consequential damages, resulting from your use of the AI-generated images.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>4. User Content</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                You are solely responsible for any content you submit or transmit to the Service, including but
                                not limited to image, messages, feedback, and design preferences.  You represent and warrant that
                                you have all necessary rights to submit or transmit such content and that it does not violate any
                                applicable laws or infringe any third-party rights. <br/>
                                By submitting or transmitting content to the Service, you grant us a non-exclusive, royalty-free,
                                perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish,
                                translate, create derivative works from, distribute, and display such content throughout the world
                                in any media.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>5. Disclaimers And Limitations Of Liability</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                The service is provided on an "as is" and "as available" basis without warranties of any kind,
                                either express or implied, including but not limited to warranties of merchantability, fitness for
                                a particular purpose, or non-infringement.  we make no warranties or representations about the accuracy
                                or completeness of the service, content, or user content. <br/>
                                In no event shall we or our licensors be liable for any indirect, special, incidental, consequential,
                                or punitive damages arising out of or in connection with the service, including but not limited to
                                loss of profits, loss of data, or interruption of business, whether based on warranty, contract,
                                tort, or any other legal theory, even if we have been advised of the possibility of such damages.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>6. Indemnification</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                You agree to indemnify, defend, and hold us and our officers, directors, employees, and agents
                                harmless from and against any claims, liabilities, damages, losses, and expenses, including
                                without limitation reasonable attorneys' fees and costs, arising out of or in any way connected
                                with your use of the Service or violation of these Terms.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>7. Modification Of Terms</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                We reserve the right to modify these Terms at any time by posting revised Terms on the Service.
                                Your continued use of the Service after the posting of revised Terms constitutes your acceptance of the revised Terms.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>8. Termination</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                We reserve the right to terminate or suspend your access to the Service, in whole or in part, at any time and for any reason, without notice or liability.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>9. Miscellaneous</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                These Terms constitute the entire agreement between you and us with respect to the Service and
                                supersede all prior or contemporaneous communications and proposals, whether oral or written,
                                between you and us.  If any provision of these Terms is found to be invalid or unenforceable,
                                the remaining provisions shall remain in full force and effect.  Our failure to enforce any right
                                or provision of these Terms shall not be deemed a waiver of such right or provision.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>10. User Responsibility</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                Users are responsible for complying with local laws and international conventions. Users must strictly
                                adhere to this statement when using the service and must not engage in any illegal or inappropriate
                                activities through the service. Users shall not publish, transmit, or share information that includes
                                any of the following: <br/>
                                1) Contrary to the fundamental principles established by the constitution. <br/>
                                2) Endangering national security, disclosing state secrets, subverting state power, or undermining
                                national unity. <br/>
                                3) Harming national honor and interests. <br/>
                                4) Inciting ethnic hatred, discrimination, or disrupting ethnic solidarity. <br/>
                                5) Disrupting national religious policies, promoting cults, or feudal superstitions.<br/>
                                6) Spreading rumors, disturbing social order, or undermining social stability.<br/>
                                7) Spreading obscene, pornographic, gambling, violent, murderous, terrorist, or criminal instigation content. <br/>
                                8) Insulting, defaming, or infringing upon the legitimate rights of others. <br/>
                                9) Containing false, fraudulent, harmful, coercive, privacy-invading, harassing, defamatory,
                                vulgar, obscene, or other morally offensive content.<br/>
                                10) Including content that is restricted or prohibited by the laws, regulations, rules, or norms
                                applicable under the jurisdiction of China or your country of residence. You should conduct
                                necessary reviews to ensure that your use of the service complies with applicable laws and
                                regulations, and you shall bear all liabilities arising therefrom. You acknowledge and agree that
                                we shall not be held liable for any violations of the above provisions in relation to the service
                                or your use of the service.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>11. Refund Policy</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                In the event that we are unable to provide the service due to reasons beyond our control, you may file a claim for compensation. Upon receiving your claim, we will review it, and if approved, we will process your claim within 7 working days.
                                The amount of compensation will be calculated based on the actual circumstances, and the compensation will be provided in the form of a credit not exceeding the maximum claim amount or a refund. In the case of a refund, it will be returned to your payment account upon completion of the refund process. Please note that the refund amount may vary depending on the payment channel and may be subject to certain fees.
                                Please note that if you have already used our service, we will not be able to provide a full refund. We will calculate the amount to be refunded based on the duration of the service you have already used.
                                If you violate our service terms or any other relevant policies, your refund request will not be processed.
                                If you have any questions or concerns, please feel free to contact us, and we will do our best to assist you in resolving the issue.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>12. Applicable Law and Jurisdiction</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                These terms are governed by the laws of the People's Republic of China. By using our website, you
                                agree that any disputes arising from your use of our website will be subject to the jurisdiction
                                of the courts of the People's Republic of China.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>13. Contact Us</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                If you have any requests, comments or feedback for this website, please feel free to contact us by email or twitter.
                            </Paragraph>
                        </Col>
                    </Row>
                </Row>
            </div>
        )}
    </div>
  );
}

export default TermsPage;

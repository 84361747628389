import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Card, Spin, Radio, Row, Col, Typography, Button, Input, message } from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';
import axios from 'axios';
import PubSub from 'pubsub-js';
import ReactGA from "react-ga4";
import { useTranslation} from 'react-i18next';

import "./CreditPage.css"
const { Title, Paragraph } = Typography;


function PaymentPage() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Credit Page" });
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    const {credit, pro, memberExpiredDate} = loginInfo;
    const [code, setCode] = useState('');
    const nation = localStorage.getItem("nation")
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paymentId = params.get('paymentId');
    const PayerID = params.get('PayerID');

    const handleCodeChange = (e) => {
      setCode(e.target.value);
    };

    const handlePayment = (total_price, name, expired) => {
        const {email} = loginInfo
        axios({
            method: "post",
            url: '/chatinterior/create_payment',
            data: {
                  "total_price": total_price,
                  "name": name,
                  "user_id": email,
                    "expired": expired
              },
          }).then(function (response) {
              const data  = response.data
              if (data.status === 200) {
                  window.location.href = data.approval_url
              } else {
                  message.error('Create order failed! Please try again later.')
              }
          })
      .catch(error => console.log(error));
    };

    const handleSubmitCode = () => {
        // 发送兑换码
          const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
          const {email, password} = loginInfo
          axios({
            method: "post",
            url: '/chatinterior/redemption',
            data: {
              "user": email,
              "password": password,
              "code": code,
              },
          }).then(function (response) {
              const data = response.data;
              if (data.status === 200) {
                  const updatedUserInfo = { ...loginInfo, credit: data.newQuota };
                  PubSub.publish('Quota Status', data.newQuota);
                  localStorage.setItem('loginInfo', JSON.stringify(updatedUserInfo));
                  // window.location.reload()
              } else if (data.status === 300) {
                  message.error(data.message)
                  setTimeout(() => {
                      localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                      navigate("/login");
                  }, 3);
              } else {
                  message.error(data.message)
              }
              setCode('');
        }).catch(function (error) {
            message.error("Sorry! Something wrong with service, please try again later.", 3);
            setCode('');
          })
    }

      const [packages, setPackages] = useState([
          {
              usages: "50 " + t("credit"),
              expired: "3 " + t("month"),
              price: "$9"
          },
          {
              usages: "150 " + t("credit"),
              expired: "6 " + t("month"),
              price: "$19",
          },
          {
              usages: "300 " + t("credit"),
              expired: "12 " + t("month"),
              price: "$39",
          },
      ]);

  const service = [
      {
          "mode": "Imagine",
          "price": "1 quota per time"
      },
      {
          "mode": "New Style Generation",
          "price": "1 quota per time"
      },
      {
          "mode": "Interior Filling",
          "price": "2 quota per time"
      },
      {
          "mode": "Interior Editing",
          "price": "2 quota per time"
      }
  ]

  useEffect(() => {
      setLoading(true);
      if (paymentId) {
          const {email} = loginInfo;
          axios({
            method: "post",
            url: '/chatinterior/purchase_paypal_callback',
            data: {
                "paymentId": paymentId,
                "PayerID": PayerID,
                "email": email
            },
          }).then(function (response) {
              const data  = response.data
              if (data.status === 200) {
                  const updatedUserInfo = { ...loginInfo, credit: data.newQuota, memberExpiredDate: data.memberExpiredDate };
                  PubSub.publish('Quota Status', data.newQuota);
                  localStorage.setItem('loginInfo', JSON.stringify(updatedUserInfo));
                  message.success(data.msg)
              } else {
                  message.error(data.msg)
              }
          })
      .catch(error => message.error('Payment confirmation failed, please contact the administrator via Twitter or email.'));
          navigate('/usage');
      }
      setLoading(false);
  }, []);

    return (
        <div>
            {loading ? (
                <div style={{ backgroundColor: '#232222', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Row justify="center">
                        <Col span={24} align="middle">
                            <img src="/logo.png" alt="AIdearoom Logo"  style={{height: '100px' }} />
                        </Col>
                        <Col span={24} align="middle">
                            <Spin size="large" style={{ marginLeft: '16px' }} />
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="container" style={{padding: '0 15%'}}>
                <Row justify="center">
                    <Col span={24} align="middle">
                          { nation === "中国" ? (
                              <Paragraph className="user-quota">
                                您目前还有<span style={{color: "#0088ff", fontWeight: "blod"}}> {credit} </span>个能量值哦！
                                  { pro === 1 && <div>您的有效期至<span style={{color: "#0088ff", fontWeight: "blod"}}> {memberExpiredDate} </span>.</div>}
                              </Paragraph>
                          ) : (
                              <div>
                                  {
                                      credit > 0 ? (
                                          <Paragraph className="user-quota">
                                                You have <span style={{color: "#0088ff", fontWeight: "blod"}}>{credit}</span> credits left. Try to <Link to='/chat'>redesign room</Link>!
                                                { pro === 1 && <div>The expiration date of your credit is <span style={{color: "#0088ff", fontWeight: "blod"}}> {memberExpiredDate}</span>.</div>}
                                            </Paragraph>

                                        ) : (
                                            <Paragraph className="user-quota">
                                                You have <span style={{color: "#0088ff", fontWeight: "blod"}}>{credit}</span> credits left. You can buy more credits to <Link to='/chat'>redesign room</Link>!
                                            </Paragraph>
                                        )
                                  }
                              </div>
                          )}
                    </Col>
                </Row>
              <Row justify="center" style={{marginBottom: "30px"}}>
                    <Col span={24} align="middle">
                        <Title level={window.innerWidth > 576 ? 1 : 2} style={{color: "white"}}>{t("credit title")}</Title>
                        {/*<Paragraph style={{fontSize: "18px", color: "gray"}}>*/}
                        {/*    {t("credit content")}*/}
                        {/*</Paragraph>*/}
                        <Paragraph style={{fontSize: "18px", color: "gray"}}>
                          {t("credit contact")}
                        </Paragraph>
                    </Col>
              </Row>
                <Row gutter={[32, 16]} className="usage-row">
                        {packages.map((pkg) => (
                          <Col xs={24} sm={24} md={8} lg={8} align="middle">
                            <Card
                              className="credit-card"
                            >
                                <p style={{fontSize: "28px", color: "white", marginBottom: 0,  fontWeight: 'bold'}}>{pkg.usages}</p>
                                <p style={{fontSize: "18px", color: "gray", marginTop: 0}}>Validity period: {pkg.expired}</p>
                                <p style={{fontSize: "30px", color: "mediumturquoise", fontWeight: "blod", textShadow: "0 0 2px mediumturquoise"}}>{pkg.price}</p>
                              <Button type="primary" onClick={() => handlePayment(pkg.price, pkg.usages, pkg.expired)}>
                                  {t("buy")}
                              </Button>
                            </Card>
                          </Col>
                        ))}
                    <Col span={24} align="middle">
                        <Paragraph style={{fontSize: "22px", color: "white"}}>{t("redemption")}</Paragraph>
                        <div style={{display: "flex", justifyContent: "center", padding: "0 10px"}}>
                            <Input placeholder="Enter your Redemption Code" style={{marginRight: "10px", width: 300, color: "white", backgroundColor: "transparent"}} value={code} onChange={handleCodeChange} />
                            <Button type="primary" onClick={handleSubmitCode}>
                                {t("submit")}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row justify="center" style={{marginBottom: "30px", marginTop: "30px"}}>
                    <Col span={24} align="middle">
                        <Title level={window.innerWidth > 576 ? 1 : 2} underline style={{color: "white"}}>{t("pro")}</Title>
                    </Col>
                    <Col span={24} align="middle">
                        <Paragraph style={{fontSize: "18px", color: "gray"}}>
                            {t("pro content")}
                        </Paragraph>
                    </Col>
                    <Col span={24} align="middle">
                        <Row style={{color: "#0088ff", width: "90%"}} justify="center" gutter={[16  , 16]}>
                            <Col xs={24} sm={24} md={8} lg={8} style={{fontSize: "20px"}} align="left">
                                <CheckCircleOutlined style={{marginRight: "5px"}}/> {t("function 1")}
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{fontSize: "20px"}} align="left">
                                <CheckCircleOutlined style={{marginRight: "5px"}}/> {t("function 2")}
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{fontSize: "20px"}} align="left">
                                <CheckCircleOutlined style={{marginRight: "5px"}}/> {t("function 3")}
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{fontSize: "20px"}} align="left">
                                <CheckCircleOutlined style={{marginRight: "5px"}}/> {t("function 4")}
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{fontSize: "20px"}} align="left">
                                <CheckCircleOutlined style={{marginRight: "5px"}}/> {t("function 5")}
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} style={{fontSize: "20px"}} align="left">
                                <CheckCircleOutlined style={{marginRight: "5px"}}/> {t("function 6")}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="start" style={{marginBottom: "30px", marginTop: "50px"}}>
                    <Col span={24} align="middle">
                        <Title level={window.innerWidth > 576 ? 1 : 2} underline style={{color: "white"}}>{t("cost title")}</Title>
                    </Col>
                    <Col span={24} align={window.innerWidth > 576 ? "left" : "middle"}>
                        <Paragraph style={{fontSize: window.innerWidth > 576 ? "20px" : "16px", color: "white"}}>{t("cost rule")}</Paragraph>
                    </Col>
                </Row>
          </div>
            )}
        </div>
    );

};

export default PaymentPage;

import React from "react";
import {message} from "antd";
export default function checkLoggedIn() {
  const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));

  if (loginInfo) {
    const expiredDate = loginInfo["expiredDate"];
    const memberExpiredDate = loginInfo["memberExpiredDate"];

    if (new Date() > new Date(expiredDate)) {
        localStorage.removeItem('loginInfo');
        message.error("Your login has expired, please login again!")
        return false;
    } else {
        const currentDate = new Date(); // 获取当前日期和时间
        const compareDate = new Date(memberExpiredDate); // 将要比较的日期转换为 Date 对象

        // 比较日期是否过期
        if (compareDate.getTime() < currentDate.getTime()) {
            const userInfo = {...loginInfo, pro: 0, memberExpiredDate: null}
            localStorage.setItem('loginInfo', JSON.stringify(userInfo));
        }
        return true;
    }
  } else {
    return false;
  }
}

import React, {useState, useEffect} from 'react';
import { Layout, Menu, Button, Drawer, Avatar, message, Dropdown  } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {  MenuOutlined, TranslationOutlined } from '@ant-design/icons';
import PubSub from 'pubsub-js';
import { useTranslation} from 'react-i18next';
import axios from "axios";

import CheckLogin from "../CheckLogin";

import "./header.css";

const { Header } = Layout;

function HeaderComponent() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const [nickName, setNickName] = useState("");
    const [Avator, setAvator] = useState("");
    const [quota, setQuota] = useState(0);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);

      const showDrawer = () => {
        setOpen(true);
      };

      const onClose = () => {
        setOpen(false);
      };

    function handleLogout() {
        localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
        setIsLogin(false); // 更新状态以触发重新渲染
        navigate("/")
      }

    const changeLanguage = (e: any) => {
        localStorage.setItem('language', e.key);
        i18n.changeLanguage(e.key);
      };

    const menu = (
        <Menu onClick={changeLanguage} className="language-dropdown">
          <Menu.Item key="en">English</Menu.Item>
          {/*<Menu.Item key="fr">Français</Menu.Item>*/}
          {/*<Menu.Item key="de">Deutsch</Menu.Item>*/}
          <Menu.Item key="zh">中文</Menu.Item>
          {/*<Menu.Item key="es">Español</Menu.Item>*/}
        </Menu>
      );


    useEffect(() => {
        window.scrollTo(0, 0);

        // const wakeup = async () => {
        //     const response = await axios.get('/chatinterior/wake_up');
        //     if (response.data.status === 200) {
        //         message.success(response.data.message, 2)
        //     }
        // };

        const LoginStatus = CheckLogin();
        if (LoginStatus) {
            setIsLogin(LoginStatus);
            const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
            const {nickname, avatarUrl, credit, email} = loginInfo
            setNickName(nickname);
            setAvator(avatarUrl);
            axios({
              method: "post",
              url: '/chatinterior/get_credit',
              data: {
                  "email": email,
              },
            }).then(async function (response) {
                const data = response.data;
                if (data.status === 200) {
                    setQuota(data.credit);
                    localStorage.setItem('loginInfo', JSON.stringify({...loginInfo, credit: data.credit}));
                } else {
                    setQuota(credit)
                }
            }).catch(function (error) {
                setQuota(credit)
            })
            // wakeup();
        }

        const LoginSub = PubSub.subscribe('Login Status', (msg, isLoggedIn) => {
            if (isLoggedIn) {
                const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
                const {nickname, avatarUrl, credit} = loginInfo;
                setNickName(nickname);
                setAvator(avatarUrl);
                setQuota(credit);
                // wakeup();
            }
            setIsLogin(isLoggedIn);
        });

        const quotaSub = PubSub.subscribe("Quota Status", (msg, quota) => {
            setQuota(quota)
        })

        return () => {
            PubSub.unsubscribe(LoginSub);
            PubSub.unsubscribe(quotaSub);
        }

      }, []);

  return (
    <Header className="header">
        <Link to="/">
            <img src="/logo.png" alt="ChatInteri" className="logo"/>
        </Link>
        <div className="menu-user">
            {
                window.innerWidth > 576 ? (
                    isLogin ? (
                        <div style={{display: "flex"}}>
                            <Menu theme="dark" selectedKeys={[]} className="menu" overflowedIndicator={<MenuOutlined />}>
                                <Link to="/design"><Menu.Item key="/design" className="menu-item">{t("Butler")}</Menu.Item></Link>
                                <Link to="/usage"><Menu.Item key="/usage" className="menu-item">{t("Credit")}</Menu.Item></Link>
                            </Menu>
                            <div style={{borderLeft: "1px solid #b7b7b7"}}></div>
                            <div className="user-info">
                                <p style={{fontSize: "16px", width: "max-content"}}>{t("quota")}: {quota}</p>
                              <Avatar src={Avator} style={{marginRight: "10px", backgroundColor: "white"}}/>
                              <span className="nickname">{nickName}</span>
                                <Button icon={<img src="/logout.png" alt="logo"id="logout-icon"/>} onClick={handleLogout} id="logout-button"></Button>
                           </div>
                        </div>
                    ) : (
                        <Link to="/login">
                            <Button size={window.innerWidth > 576 ? "large" : "normal"} type="primary" className="user-login">
                                <span className="user-text">{t("Sign In")}</span>
                            </Button>
                       </Link>
                    )
                ) : (
                    isLogin ? (
                        <div className="user-info">
                                 <p style={{fontSize: "16px", color: "white"}}>{t("quota")}: {quota}</p>
                              <Avatar src={Avator} style={{marginRight: "10px", backgroundColor: "white"}} />
                              <span className="nickname" style={{marginRight: "10px"}}>{nickName}</span>
                            <Button type="primary" onClick={showDrawer} icon={<MenuOutlined/>}></Button>
                              <Drawer title={
                                  <Button icon={<img src="/logout.png" id="logout-icon"/>} onClick={handleLogout} id="logout-button"></Button>
                              }className="dark-theme" width="40%" placement="right" onClose={onClose} open={open}>
                                <Menu theme="dark" mode="vertical"  selectedKeys={[]} style={{backgroundColor: "transparent", color: "white"}} overflowedIndicator={<MenuOutlined />}>
                                    <Menu.Item key="/design" className="menu-item" onClick={onClose}><Link to="/design">{t("Butler")}</Link></Menu.Item>
                                    <Link to="/usage"><Menu.Item key="/usage" className="menu-item" onClick={onClose}>{t("Credit")}</Menu.Item></Link>
                                </Menu>
                              </Drawer>
                        </div>
                    ) : (
                        <Link to="/login">
                            <Button size={window.innerWidth > 576 ? "large" : "normal"} type="primary" className="user-login">
                                <span className="user-text">{t("Sign In")}</span>
                            </Button>
                        </Link>
                    )
                )
            }
            <Dropdown overlay={menu} trigger={['hover']}>
              <Button className="language-button">
                <TranslationOutlined style={{ color: 'white',  fontSize: '22px' }}/>
              </Button>
            </Dropdown>
        </div>
        {/*// <div>*/}
        {/*    <Dropdown overlay={menu} trigger={['hover']}>*/}
        {/*      <Button className="language-button">*/}
        {/*        <TranslationOutlined style={{ color: 'white',  fontSize: '22px' }}/>*/}
        {/*      </Button>*/}
        {/*    </Dropdown>*/}
        {/*</div>*/}
    </Header>
  );
}

export default HeaderComponent;

import React from 'react';
import { Layout, Col, Row, Popover, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { GithubOutlined, TwitterOutlined, MailOutlined, WechatOutlined } from '@ant-design/icons';
import { useTranslation} from 'react-i18next'


import "./footer.css";
const { Footer } = Layout;


function FooterComponent() {
    const { t } = useTranslation();
    const nation = localStorage.getItem("nation")
    const content = (
        <div>
          <img src="/qrcode.jpg" alt="QR Code" style={{height: 120}}/>
        </div>
    );

  return (
    <Footer id="footer">
        <Row justify="space-between">
            <Col xs={24} sm={24} md={12} lg={12} align={window.innerWidth < 576 ? "middle":"left"}>
                <div className="footer-left">© 2024 AIdeaRoom. {t("right")}</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} align={window.innerWidth < 576 ? "middle":"right"}>
                <div className="footer-right">
                    {/*<a style={{color: "gray"}} href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023074530号-1</a>*/}
                    <Link to="/terms">
                        {/*<Button type="text">*/}
                            <span className='footer-text'>{t("terms")}</span>
                        {/*</Button>*/}
                   </Link>
                    <Link to="/privacy">
                        {/*<Button type="text">*/}
                            <span className='footer-text'>{t("privacy")}</span>
                        {/*</Button>*/}
                   </Link>
                    { nation === "中国" ? (
                        <Popover content={content}  trigger="hover">
                            <WechatOutlined  style={{marginLeft: "10px", fontSize: 25, marginBottom: 0, color: "#95ec69"}}/>
                        </Popover>
                    ) : (
                        <a style={{marginRight: "5px"}} href="https://twitter.com/MagicianKenChan" target="_blank"><TwitterOutlined /></a>
                    )}
                 <a style={{marginRight: "5px"}} href="mailto:q1218605102@gmail.com"><MailOutlined /></a>
              </div>
            </Col>
        </Row>
    </Footer>
  );
}

export default FooterComponent;

import React, {useEffect, useState} from "react";
import { Link, useNavigate  } from 'react-router-dom';
import {Row, Col, Typography, Button, message, Card, Popconfirm, Pagination } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import { useTranslation} from 'react-i18next';


import CheckLogin from "../../components/CheckLogin";
import withAnalyticsTracking from "../../components/AnalyticsTracking";
import "./DesignPage.css"

const { Title, Paragraph } = Typography;

function DesignPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userRoom, setUserRoom] = useState({});
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [totalItems, setTotalItems] = useState(0);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLoading(true);
        const {email, password} = loginInfo
        axios({
          method: "post",
          url: '/chatinterior/get_roominfo',
          data: {
              "user": email,
              "password": password,
              "pageSize": pageSize,
              "page": page
          },
        }).then(function (response) {
            const data = response.data;
            if (data.success) {
                const userRoom = data.rooms;
                if (userRoom.length > 0) {
                    setTotalItems(data.totalItems);
                    setUserRoom(userRoom);
                } else {
                    message.info("Go ahead and generate your first room!")
                }
                setLoading(false);
            } else {
                message.error("Your login have expired, please login again!")
                setTimeout(() => {
                    localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                    navigate("/login");
                    window.location.reload()
                }, 1000);
            }
        }).catch(function (error) {
          message.error("Something wrong, please try again later, sorry!")
            setTimeout(() => {
                    localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                    navigate("/login");
                }, 1000);
        })
    };

    const handleDelete = (index) => {
      // 发送删除请求
        const {email, password} = loginInfo
        axios({
              method: "post",
              url: '/chatinterior/remove_room',
              data: {
                  "user": email,
                  "password": password,
                  "roomId": userRoom[index]["room_id"]
              },
            }).then(function (response) {
                const data = response.data;
                if (data.success) {
                    const newUserRoom = [...userRoom];
                    newUserRoom.splice(index, 1);
                    setUserRoom(newUserRoom);
                } else {
                    message.error("Your login have expired, please login again!", 3);
                    setTimeout(() => {
                        localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                        navigate("/login");
                    }, 50);
                }
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const LoginStatus = CheckLogin();
        if (!LoginStatus) {
            navigate('/login');
        } else {
            const {email, password} = loginInfo
            axios({
              method: "post",
              url: '/chatinterior/get_roominfo',
              data: {
                  "user": email,
                  "password": password,
                  "pageSize": pageSize,
                  "page": currentPage
              },
            }).then(function (response) {
                const data = response.data;

                if (data.success) {
                    const userRoom = data.rooms;
                    if (userRoom.length > 0) {
                        setTotalItems(data.totalItems);
                        setUserRoom(userRoom);
                    } else {
                        message.info("Go ahead and redesign your first room!")
                    }
                    setLoading(false);
                } else {
                    message.error("Your login details have expired, please login again!")
                    setTimeout(() => {
                        localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                        navigate("/login");
                        window.location.reload()
                    }, 1000);
                }
            }).catch(function (error) {
              message.error("Something wrong, please try again later, sorry!")
                setTimeout(() => {
                        localStorage.removeItem('loginInfo'); // 清除 localStorage 中的用户信息
                        navigate("/login");
                    }, 1000);
            })
        }
    }, []);

    return (
        <div className="container" style={{marginTop: "100px", padding: '0 15%'}}>
            <Row justify="center">
                <Col span={24} align="middle">
                    <Title id="design-title" style={{color: "white"}} level={1}>{t("bulter title")}</Title>
                </Col>
                <Col span={24} align="middle">
                    <Paragraph style={{color: "white", fontSize: "18px"}} level={4}>{t("bulter content")}</Paragraph>
                </Col>
            </Row>
             <Row justify="center" >
                <Col lg={12} md={12} xs={24} sm={24} align="middle">
                  {/*  <Select*/}
                  {/*  style={{ width: '100%' }}*/}
                  {/*  placeholder="Room Type"*/}
                  {/*  onChange={handleSelectChange}*/}
                  {/*>*/}
                  {/*  <Option value="feature1">特征1</Option>*/}
                  {/*  <Option value="feature2">特征2</Option>*/}
                  {/*  <Option value="feature3">特征3</Option>*/}
                  {/*</Select>*/}
                </Col>
                <Col span={24} align="middle">
                    <Link to="/chat">
                        <Button size="normal">{t("bulter buttom")}</Button>
                    </Link>
                </Col>
            </Row>
                <Row justify="center" gutter={[16, 16]}>
                    {Object.entries(userRoom).map(([key,item]) => (
                        <Col lg={8} md={12} xs={24} sm={24} align="middle">
                            <Card className="room-card" hoverable loading={loading} key={key}>
                                <Row justify="center">
                                    <Col span={24} align="middle" style={{display: "flex", justifyContent: "center", alignItem: "center"}}>
                                            <Paragraph className="card-text" style={{margin: 0, flexGrow: 1}} level={4}>{t(item["room_type"])}</Paragraph>
                                            <Popconfirm
                                              title={t("delete room")}
                                              onConfirm={() => handleDelete(key)}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                                <Button type="text" icon={<DeleteOutlined style={{color: "gray", flexGrow: 0}}/>}></Button>
                                            </Popconfirm>
                                    </Col>
                                        <Col span={24} align="middle">
                                            <div style={{padding: "5px", paddingTop: "10px"}}>
                                                <Link to='/chat' state={{ isUpload: true, roomId: item["room_id"], roomInfo: item }}>
                                                    { item.status === 1 ? (
                                                        <img alt={`Redesign Room ${key}`} src={`https://www.aidearoom.com/${item["image_url"]}`} alt="redesign interior" className="room-image"/>
                                                    ) : (
                                                        <div className="waiting-box" style={{backgroundImage: `url(/chatinterior/image?${encodeURIComponent("filepath")}=${encodeURIComponent(item["origin_url"])})`}}>
                                                            <Paragraph style={{color: "white", fontSize: 24, fontWeight: 'bold'}}>
                                                                Waiting Process
                                                            </Paragraph>
                                                        </div>
                                                    )}
                                                </Link>
                                            </div>
                                        </Col>
                                </Row>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row justify="center" style={{marginTop: 20}}>
                    <Col span={24} align="middle" style={{"display": "flex", "justifyContent": "center"}}>
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalItems}
                            onChange={handlePageChange}
                            className="dark-pagination"
                            hideOnSinglePage
                            showSizeChanger={false}
                          />
                    </Col>
                </Row>
        </div>
    )
}

export default withAnalyticsTracking(DesignPage);
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Input, Button, Col, Card, Modal, message } from 'antd';
import axios from "axios";

import { CopyOutlined, CheckOutlined, SendOutlined, LoadingOutlined} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import checkLogin from "../CheckLogin";
import './ChatBox.css'; // 导入样式文件

const { TextArea } = Input;


async function compressImage(imageFile, targetWidth, targetHeight) {
  const img = new Image();
  img.src = URL.createObjectURL(imageFile);

  await new Promise((resolve) => {
    img.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > targetWidth) {
      height *= targetWidth / width;
      width = targetWidth;
    }
  } else {
    if (height > targetHeight) {
      width *= targetHeight / height;
      height = targetHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  ctx.drawImage(img, 0, 0, width, height);

  const dataUrl = canvas.toDataURL(imageFile.type);

  return fetch(dataUrl).then((res) => res.blob());
}

async function fileToBlob(file) {
  const compressedBlob = await compressImage(file, 768, 768);
  return compressedBlob;
  // return new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     const blob = new Blob([event.target.result], { type: file.type });
  //     resolve(blob);
  //   };
  //   reader.onerror = (error) => {
  //     reject(error);
  //   };
  //   reader.readAsArrayBuffer(new Blob([file]));
  // });
}


function ChatBox(props) {
  const [messages, setMessages] = useState([
      {id: 1, type: "system", text: "Nice to meet you, here is your new room!",
                  img: null, loading: true, avatorUrl: "/favicon.ico"},
  ]);
  const initialSrc = props.ImgSrc;
  const mode = props.mode;
  const roomType = props.roomType;
  const roomStyle = props.style;
  const postiveElements = props.postiveElements;
  const negativeElements = props.negativeElements;
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('loginInfo'));
  const {email, img_quota} = userInfo;
  const quota = img_quota;
  const [roomId, setRoomId] = useState(props.roomId)

  const [inputValue, setInputValue] = useState('');

  const chatBoxRef = useRef(null);
  const chatHistory = useRef([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleImageClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCopy = (messageId) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[messageId-1].copy = true
      return updatedMessages
    });
    setTimeout(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[messageId-1].copy = false
        return updatedMessages
      });
    }, 2000);
  };

  async function handleSendMessage (event) {
    if (event.key === 'Enter' && messages[messages.length - 1].loading) {
      return;
    }

    if (inputValue.trim().replace(/\n/g, "") === '') {
      if (messages.length === 0 || messages[messages.length-1].type === 'empty_alert') {
        return;
      } else {
        const newMessage = { id: messages.length + 1, type: 'empty_alert', text: "Please input your instruction！"};
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInputValue(prevInputValue => '');
      }
    } else {
      const newMessage = { id: messages.length + 1, type: 'user', text: inputValue};
      const userInput = inputValue;
      const LoginStatus = checkLogin();
      chatHistory.current.push({"role": "user", "content": userInput})
      setMessages(prevMessage => [...prevMessage, newMessage]);
      setInputValue(prevInputValue => '');

      if (!LoginStatus) {
        const newResponse = {
          id: newMessage.id + 1,
          type: 'reply',
          text: "Sorry, you are still not login, please login fisrt!",
          avatorUrl: "/favicon.ico",
          loading: false,
          copy: false
        };
        setMessages(prevMessage => [...prevMessage, newResponse]);
      } else {
        const newResponse = {
          id: newMessage.id + 1,
          type: 'reply',
          text: "",
          avatorUrl: "/favicon.ico",
          loading: true,
          copy: false
        };
        setMessages(prevMessage => [...prevMessage, newResponse]);

        // setTimeout(() => {
        //   setMessages(prevMessages => {
        //     const updatedMessages = [...prevMessages];
        //     updatedMessages[updatedMessages.length - 1].text = "你好".repeat(5);
        //     updatedMessages[updatedMessages.length - 1].loading = false;
        //     sessionStorage.setItem("msg", JSON.stringify(updatedMessages));
        //     return updatedMessages;
        //   });
        // }, 1000);

        // const lastMsgWithImg = messages.reduceRight((acc, curr) => {
        //     if (curr.img !== null) {
        //       return curr;
        //     }
        //     return acc;
        //   }, null);
        // const blob = await getBlob(lastMsgWithImg.img);

        const formData = new FormData();
        formData.append('msg', JSON.stringify(chatHistory.current.slice(-5)));
        // formData.append('image', blob, lastMsgWithImg.img.split('/').pop());
        formData.append("user", email);
        formData.append("roomId", roomId);
        formData.append("quota", quota);
        const index = messages.slice().reverse().findIndex(item => item.img);
        const lastImgDict = index >= 0 ? messages[messages.length - 1 - index] : null;
        const lastImg = lastImgDict ? lastImgDict.img : null;
        formData.append("roomUrl", lastImg)
        axios({
          method: "post",
          url: '/chatinterior/chat',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData,
        }).then(function (response) {
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            console.log(response.data)
            updatedMessages[updatedMessages.length - 1].text = response.data.message;
            chatHistory.current.push({"role": "assistant", "content": response.data.message})
            if (response.data.image) {
              // const imageUrl = URL.createObjectURL(response.data.image);
              updatedMessages[updatedMessages.length - 1].img = response.data.image;
            } else {
              updatedMessages[updatedMessages.length - 1].img = "";
            }
            updatedMessages[updatedMessages.length - 1].loading = false;
            return updatedMessages;
          });
          userInfo.img_quota = userInfo.img_quota - 1;
          localStorage.setItem('loginInfo', JSON.stringify(userInfo));
        }).catch(function (error) {
          console.log(error);
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = "抱歉哦，服务器正在维护中~";
            updatedMessages[updatedMessages.length - 1].loading = false;
            return updatedMessages;
          });
        })
      }
    }
  };

  useEffect(() => {
    // 滚动到底部
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    setInputValue("")
  }, [messages]);

  useEffect(() => {
    const uploadRoom = async () => {
      if (!roomId) {
        const formData = new FormData();
        formData.append('type', 'text');
        formData.append("mode", mode)
        formData.append('roomType', roomType);
        formData.append("roomStyle", roomStyle);
        formData.append("postiveElements", postiveElements);
        formData.append("negativeElements", negativeElements);
        formData.append("user", email);
        formData.append("quota", quota);
        if (initialSrc != "") {
          const blob = await fileToBlob(initialSrc);
          console.log(blob)
          formData.append('image', blob);
        }
        axios({
          method: "post",
          url: '/chatinterior/upload',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData,
        }).then(function (response) {
          if (response.data.success) {
            setMessages(prevMessages => {
              const updatedMessages = [...prevMessages];
              if (response.data.image) {
                updatedMessages[updatedMessages.length - 1].img = response.data.image;
              } else {
                updatedMessages[updatedMessages.length - 1].img = "";
              }
              updatedMessages[updatedMessages.length - 1].loading = false;
              return updatedMessages;
            });
            setRoomId(response.data.roomId);
            userInfo.img_quota = userInfo.img_quota - 2;
            localStorage.setItem('loginInfo', JSON.stringify(userInfo));
          } else {
            message.error(response.data.message, 3)
            setTimeout(() => {
            window.location.reload();
          }, 3000); // 等待1秒后重新载入页面
          }
        }).catch(function (error) {
          message.error("Sorry! Something wrong with service, please try again later.", 3);
          setTimeout(() => {
            window.location.reload();
          }, 3000); // 等待1秒后重新载入页面
        })
      } else {
        const {msg} = props;
        console.log(msg)
        const new_msg = Object.entries(msg).map(([key, value]) => {
          // 返回包含key和处理后的value的新数组
          const new_dict = {id: parseInt(key) + 1, type: value.bot_role, text: value.content, img: value.image_url}
          if (value.role != "user") {
            const new_dict = {...new_dict, avatorUrl: "/favicon.ico", loading: false, copy: false}
          }

          return new_dict;
        });
        setMessages(new_msg);
      }
    };
    uploadRoom();
  }, []);


  return (
    <div className="chat-box-container">
      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((message) => (
          <Col span={24} align={`${message.type === 'user' ? 'end' : 'start'}`}>
            {
              window.innerWidth < 576 ? (
                  message.type === 'user' ? (
                        <div className="message-container-user" style={{justifyContent: "end"}}>
                            <div key={message.id} className='message user'>
                                {message.text}
                             </div>
                          <img className="avatar-user" src="/avatar.png" alt="avatar"/>
                        </div>
                    ) : (
                        <div className="message-container-assistant" key={message.id}>
                          <img className="avatar-reply" src={message.avatorUrl} alt="avatar"/>
                          <div className='message'>
                            {message.text}
                            {message.loading && <LoadingOutlined/> }
                            {message.img && (
                                <Card hoverable onClick={handleImageClick}>
                                  <img
                                    src={`/chatinterior/image/${message.img}`}
                                    alt="uploaded"
                                    style={{ maxHeight: '300px', width: '100%', maxWidth: "300px"}}
                                    download="image.jpg"
                                  />
                                </Card>
                            )}
                            {
                              isModalVisible && (
                                  <Modal visible={isModalVisible} onCancel={handleCloseModal} footer={null}>
                                    <img alt="room image" src={`/chatinterior/image/${message.img}`} className="modal-image"  download="image.jpg" style={{ maxHeight: '100%', width: '100%', maxWidth: "100%"}}/>
                                  </Modal>
                                )
                            }
                         </div>
                          {
                            !message.loading && (
                              <CopyToClipboard text={message.text}>
                                <Button onClick={() => handleCopy(message.id)} type="link" icon={message.copy ? <CheckOutlined /> : <CopyOutlined/> }></Button>
                              </CopyToClipboard>
                            )
                          }
                        </div>
                    )
                  ) : (
                      message.type === 'user' ? (
                    <div className="message-container" style={{justifyContent: "end"}}>
                        <div key={message.id} className='message user'>
                            {message.text}
                         </div>
                      <img className="avatar-user" src="/avatar.png" alt="avatar"/>
                    </div>
                ) : (
                    <div className="message-container" key={message.id}>
                      <img className="avatar-reply" src={message.avatorUrl} alt="avatar"/>
                      <div className='message'>
                        {message.text}
                        {message.loading && <LoadingOutlined style={{justifyContent: "center"}}/>}
                        {message.img && (
                            <Card hoverable onClick={handleImageClick}>
                              <img
                                src={`/chatinterior/image/${message.img}`}
                                alt="uploaded"
                                style={{ maxHeight: '300px', width: '100%', maxWidth: "300px"}}
                                download="image.jpg"
                              />
                            </Card>
                        )}
                        {
                          isModalVisible && (
                              <Modal visible={isModalVisible} width={640} height={512} onCancel={handleCloseModal} footer={null}>
                                <img alt="room image" src={`/chatinterior/image/${message.img}`} className="modal-image"  download="image.jpg" style={{ maxHeight: '100%', width: '100%', maxWidth: "100%"}}/>
                              </Modal>
                            )
                        }
                     </div>
                      {
                        !message.loading && (
                          <CopyToClipboard text={message.text}>
                            <Button onClick={() => handleCopy(message.id)} type="link" icon={message.copy ? <CheckOutlined /> : <CopyOutlined/> }>{message.copy && "copied!"}</Button>
                          </CopyToClipboard>
                        )
                      }
                    </div>
                )
              )
            }
          </Col>
        ))}
      </div>
      <div className="input-container">
        <TextArea className="input"  autoSize value={inputValue} allowClear={true} onPressEnter={handleSendMessage} maxLength={1500} onChange={handleInputChange} placeholder="Type your demand" />
        <Button disabled={messages[messages.length-1].loading} type={window.innerWidth > 576 ? "primary" : "text"} className="send-button" onClick={handleSendMessage} icon={<SendOutlined/>}></Button>
      </div>
    </div>
  );
}

export default ChatBox;

import React, {useEffect, useState, useRef} from "react";
import { useNavigate  } from 'react-router-dom';
import {Row, Col, Spin, Typography, Button, Card, Image, Steps, Carousel, message } from "antd";
import axios from "axios";
import PubSub from 'pubsub-js';
import { useTranslation} from 'react-i18next';

import CheckLogin from "../../components/CheckLogin";
import "./home.css"

const { Title, Paragraph } = Typography;

function HomePage(props) {
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const [recentRooms, setRecentRooms] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    const [selectedSlide, setSelectedSlide] = useState(0);
    const images = ['/1.jpg', '/2.jpg', '/3.jpg', '/4.jpg', '/5.jpg', '/6.jpg', '/7.jpg', '/8.jpg'];
    const carouselRef = React.createRef();

    const settings = {
        centerMode: true,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        lazyLoad: true,
        dots: false,
        centerPadding: "30px",
        autoplaySpeed: 3000,
        speed: 500,
        beforeChange: (current, next) => setSelectedSlide(next),
        ref: carouselRef
      };
      const onChange = (value: number) => {
        setCurrent(value);
      };

    function handleClick() {
        const LoginStatus = CheckLogin();
        if (LoginStatus) {
        // 用户已登录
        navigate("/chat");
        } else {
        // 用户未登录，跳转到登录页面
        navigate('/login');
    }
}

    async function getImagePair(item) {
        const [originResponse, targetResponse] = await Promise.all([
          fetch(`/chatinterior/image?${encodeURIComponent("filepath")}=${encodeURIComponent(item.origin_url)}`),
          fetch(`/chatinterior/image?${encodeURIComponent("filepath")}=${encodeURIComponent(item.image_url)}`)
        ]);

        const [originBase64, targetBase64] = await Promise.all([
          originResponse.blob().then((blob) => URL.createObjectURL(blob)),
          targetResponse.blob().then((blob) => URL.createObjectURL(blob))
        ]);

        return { originImage: originBase64, targetImage: targetBase64 };
      }


    useEffect(() => {
        window.scrollTo(0, 0);
        // message.info('It will take up to 10 minutes for all functions to finish loading.')
        if (code !== null) {
             axios({
                method: "post",
                url: '/chatinterior/login',
                data: {
                  "code": code
                },
            }).then(function (response) {
                const data = response.data;
                if (data.status === 200) {
                    const userInfo = {
                        "email": data.userInfo["openid"],
                        "avatarUrl": (data.userInfo["avatar_url"] === "" || data.userInfo["avatar_url"] === null) ?
                            "/default.jpg" : data.userInfo["avatar_url"],
                        "password": data.userInfo["password"],
                        "nickName": (data.userInfo["nickname"] === "" || data.userInfo["nickname"] === null )? "微信用户" : data.userInfo["nickname"],
                        "pro": data.userInfo["pro"],
                        "image_balance": data.userInfo["image_balance"],
                        "text_balance": data.userInfo["text_balance"],
                        "memberExpiredDate": data.userInfo["expiration_date"],
                        "expriedDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                    }
                    localStorage.setItem('loginInfo', JSON.stringify(userInfo));
                    PubSub.publish('Login Status', true);
                    message.success(data.message, 2);
                    const newUrl = window.location.href.split('?')[0]; 
                    window.history.replaceState({}, document.title, newUrl);
                } else {
                    message.error(data.message, 2)
                }
                setLoading(false);
             }).catch(function (error) {
                message.error('Sorry, failed to get user information, please try again later!', 2);
                return ;
            })
        } else{
            setLoading(false);
        }

        // async function getImagePairs(item) {
        //   const pairs = await Promise.all(item.map(getImagePair));
        //   setRecentRooms(pairs);
        // }
        //
        // axios({
        //   method: "get",
        //   url: '/chatinterior/get_recent_room',
        // }).then(async function (response) {
        //     const data = response.data;
        //     if (data.success) {
        //         getImagePairs(data.recentRooms);
        //     }
        // })

    }, []);

    return (
        <div>
            {loading ? (
                <div style={{ backgroundColor: '#232222', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Row justify="center">
                        <Col span={24} align="middle">
                            <img src="/logo.png" alt="AIdearoom Logo"  style={{height: '100px' }} />
                        </Col>
                        <Col span={24} align="middle">
                            <Spin size="large" style={{ marginLeft: '16px' }} />
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="container">
                    <Row justify="center" className="row" id="home-row">
                        <Col span={24} align="middle">
                                <Title level={1} style={{ color: '#0088ff', fontSize: window.innerWidth > 576 ? "70px" : "40px", fontFamily: "'Dancing Script', cursive", textShadow: "0 0 30px #0088ff", marginBottom: "5px"}} id="home-title">AIdeaRoom</Title>
                                <Title level={1} style={{ color: 'white'}} id="home-title">{t("detail")}</Title>
                                <Button onClick={handleClick} type="primary" size={window.innerWidth > 576 ? "large": "normal"} style={{ height: "auto"}} id="home-buttom">
                                    {t("start")}
                                </Button>
                        </Col>
                    </Row>
                    <Row justify="center" className="row">
                        <Col span={24} align="middle" className="col-title">
                            <Title level={1} style={{ color: 'white'}} className="title-text" underline={true}>{t("use")}</Title>
                            <Paragraph  className="home-text">{t("use detail")}</Paragraph>
                        </Col>
                        <Row className={window.innerWidth > 576 && "row"} justify="center" gutter={[48, 48]}>
                            <Col xs={24} sm={24} md={8} lg={8} align="middle">
                                <Card
                                    className='use-case-card'
                                    style={{width: window.innerWidth > 576 ? window.innerWidth*0.2 : window.innerWidth *0.85}}
                                >
                                    <Title level={3} style={{ color: '#1677ff', marginTop: 0}}>{t("manuscript")}</Title>
                                    <Image alt='interior design from manuscript' src={'/manuscript.jpg'} preview={false} />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} align="middle">
                                <Card
                                    className='use-case-card'
                                    style={{width: window.innerWidth > 576 ? window.innerWidth*0.2 : window.innerWidth *0.85}}
                                >
                                    <Title level={3} style={{ color: '#1677ff', marginTop: 0}}>{t("rough")}</Title>
                                    <Image alt='Rough Renovation' src={'/rough.jpg'} preview={false} />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} align="middle">
                                <Card
                                    className='use-case-card'
                                    style={{width: window.innerWidth > 576 ? window.innerWidth*0.2 : window.innerWidth *0.85}}
                                >
                                    <Title level={3} style={{ color: '#1677ff', marginTop: 0}}>{t("transform")}</Title>
                                    <Image alt='interior design from manuscript' src={'/style.jpg'} preview={false} />
                                </Card>
                            </Col>
                        </Row>
                    </Row>
                    <Row justify="center" className="row" style={{padding: '0 !important'}}>
                        <Col span={24} align="middle" className="col-title">
                            <Title level={1} style={{ color: 'white'}} className="title-text" underline={true}>{t("Exhibition Wall")}</Title>
                            <Paragraph  className="home-text">{t("wall detail")}</Paragraph>
                        </Col>
                        <Col span={24} align="middle">
                            <div className="custom-carousel">
                                <Carousel {...settings} slidesToShow={window.innerWidth > 576 ? 3 : 1}>
                                    {
                                        images.map((url, idx) => (
                                            <div
                                                key={idx}
                                                style={{width: window.innerWidth > 576 ? window.innerWidth * 0.33 : window.innerWidth * 0.9, height: window.innerWidth > 576 ? window.innerWidth * 0.33 * 0.7 : window.innerWidth * 0.9 * 0.7}}
                                                className={`slide ${
                                                  idx === selectedSlide ? "active-slide" : "inactive-slide"
                                                }`}
                                                onClick={() => {
                                                  // 调用内部 goTo 方法滚动到指定幻灯片
                                                  carouselRef.current.goTo(idx);
                                                }}
                                              >
                                                <Image src={url}
                                                       key={idx}
                                                       width={window.innerWidth > 576 ? window.innerWidth * 0.33 : window.innerWidth * 0.9}
                                                       height={window.innerWidth > 576 ? window.innerWidth * 0.33 * 0.7 : window.innerWidth * 0.9 * 0.7}
                                                       alt={`Redesign Room ${idx}`}
                                                       style={{borderRadius: "10px"}}
                                                       preview={false}
                                                />
                                              </div>
                                        ))
                                    }
                                  </Carousel>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

export default HomePage;

import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout, Spin, Row, Col } from 'antd';
import axios from "axios";
import { useTranslation} from 'react-i18next';
import ReactGA from "react-ga4";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/HomePage";
import Chat from "./pages/ChatPage";
import Login from "./pages/LoginPage";
import Design from "./pages/DesignPage";
import Usage from "./pages/CreditPage"
import Terms from "./pages/TermsPage";
import Privacy from "./pages/PrivacyPage"
import './App.css';


const { Content } = Layout;
ReactGA.initialize('G-PF9KF3E8WG');

function App() {
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();

  useEffect(() => {
    const checkIPInChina = async () => {
      try {
        const language = localStorage.getItem('language');
        if (language === null) {
          i18n.changeLanguage("en");
        } else {
          i18n.changeLanguage(language);
        }
        localStorage.setItem('nation', 'world')

        // const response = await axios.get('/chatinterior/getNation');
        // // 检查响应中的数据，判断IP是否在中国
        // if (response.data.result.ad_info.nation === '中国') {
        //   localStorage.setItem('nation', response.data.result.ad_info.nation);
        //   i18n.changeLanguage("zh");
        // } else {
        //    localStorage.setItem('nation', response.data.result.ad_info.nation);
        //     i18n.changeLanguage("en");
        // }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    // 调用函数进行IP检测
    checkIPInChina();
  }, []);

  return (
    <Router>
        { loading ? (
            <div style={{ backgroundColor: '#232222', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Row justify="center">
                    <Col span={24} align="middle">
                        <img src="/logo.png" alt="AIdearoom"  style={{height: '100px' }} />
                    </Col>
                    <Col span={24} align="middle">
                        <Spin size="large" style={{ marginLeft: '16px' }} />
                    </Col>
                </Row>
            </div>
        ) : (
          <Layout className="page-layout" style={{ minHeight: '100vh'}}>
            <Header />
                <Content>
                    <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/chat" element={<Chat/>} />
                        <Route path="/design" element={<Design/>} />
                        <Route path="/usage" element={<Usage/>} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/terms" element={<Terms/>} />
                        <Route path="/privacy" element={<Privacy/>} />
                    </Routes>
                </Content>
            <Footer/>
          </Layout>
        )}
    </Router>
  );
}

export default App;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en.json';
import zhTranslation from './zh.json';

// 初始化i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation
    },
    zh: {
      translation: zhTranslation
    }
  },
  lng: 'en', // 默认语言
  fallbackLng: 'en', // 如果没有找到对应的翻译，默认使用英文
  interpolation: {
    escapeValue: false // 禁用默认的HTML转义
  }
});

export default i18n;

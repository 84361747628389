import React, {useState, useEffect} from 'react';
import { Typography, Row, Col } from 'antd';

import "./Privacy.css"

const { Title, Paragraph } = Typography;

function PrivacyPage() {
    const nation = localStorage.getItem("nation");

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div className="container terms-container">
        { (nation === "中国") ? (
            <div>
                <Row>
                    <Title level={2}>隐私政策</Title>
                </Row>
                <Row>
                    <Paragraph>更新时间: 2023-05-02</Paragraph>
                    <Paragraph style={{fontSize: "18px"}}>
                        该隐私政策用于解释当您使用我们的网站时，我们如何收集、使用、披露和保护您的个人信息。请在使用我们的网站之前仔细阅读和理解该隐私政策。
                        若您决定继续使用我们的网站，意味着您同意本隐私政策中所描述的信息收集、使用和披露做法。如果您对本隐私政策或您的个人信息有任何问题或顾虑，
                        请与我们联系。我们将尽力回答您的问题并解决您的顾虑。
                    </Paragraph>
                </Row>
                <Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>1. 信息收集</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们可能会收集您提供的个人信息，包括但不限于姓名、电子邮件地址、联系电话等。此外，我们还可能通过使用技术工具
                                （如Cookie和网络服务器日志文件）自动收集关于您的匿名信息。一旦您完成注册，即被视为同意我们收集上述数据。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>2. 信息使用</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们收集的个人信息将用于以下目的：<br/>
                                    1）提供您所请求的服务或信息；<br/>
                                    2）处理您的订单或请求；<br/>
                                    3）向您发送有关我们的产品和服务的更新和通知；<br/>
                                    4）改进我们的网站和服务；<br/>
                                    5）个性化您的用户体验；<br/>
                                    6）履行我们的法律义务。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>3. 信息披露</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                在以下情况下，我们将披露您的个人信息：<br/>
                                    1）在您明确同意的情况下；<br/>
                                    2）根据法律要求或政府机关的合法请求；<br/>
                                    3）为了保护我们的合法利益或其他用户的安全；<br/>
                                    4）与我们的合作伙伴和第三方服务提供商共享，以便提供您所请求的服务。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>4. 信息保护</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们采取合理的安全措施来保护您的个人信息，防止未经授权的访问、使用、修改或披露。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>5. Cookies等类似技术</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们的网站可能会使用Cookie和类似的技术来收集和存储某些信息。您可以根据自己的偏好管理Cookie设置，但请注意，禁用Cookie可能会影响您对网站功能的访问和体验。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>6. 第三方链接</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们的网站可能包含指向第三方网站或服务的链接，我们会在相应的服务页面注明。我们对这些第三方网站的隐私政策或内容不负责任。在使用这些服务之前，请阅读它们的隐私政策。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>7. 隐私权利</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                根据适用法律，您有权访问、更正、删除和限制使用您的个人信息。要行使这些权利或提出与隐私有关的问题，请联系我们的隐私团队。
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>8. 对隐私政策的修改</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                我们保留随时更改本隐私政策的权利。对于重大变更，我们将通过在网站上发布更新的隐私政策来通知您。
                            </Paragraph>
                        </Col>
                    </Row>
                </Row>
            </div>
        ) : (
            <div style={{padding: "0 15%"}}>
                <Row>
                    <Title level={2}>Privacy Policy</Title>
                </Row>
                <Row>
                    <Paragraph>Last updated time: 2023-05-02</Paragraph>
                    <Paragraph style={{fontSize: "18px"}}>
                        This Privacy Policy is intended to explain how we collect, use, disclose and protect your personal
                        information when you use our website. Please read and understand this privacy policy carefully before
                        using our website. By continuing to use our website, you are consenting to the information collection,
                        use and disclosure practices described in this Privacy Policy. If you have any questions or concerns
                        about this Privacy Policy or your personal information, please contact us. We
                        will do our best to answer your questions and address your concerns.
                    </Paragraph>
                </Row>
                <Row>
                    <Col span={24}>
                        <Title style={{textDecoration: 'underline'}} level={4}>1. Information Collection</Title>
                    </Col>
                    <Col span={24}>
                        <Paragraph style={{fontSize: "18px"}}>
                            We may collect personal information provided by you, including but not limited to name, email address,
                            contact number, etc. In addition, we may also collect anonymous information about you automatically
                            through the use of technical tools such as cookies and web server log files. Once you complete registration,
                            you are deemed to have consented to our collection of the above data.
                        </Paragraph>
                    </Col>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>2. Information Use</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                The personal information we collect will be used for the following purposes:<br/>
                                1) To provide the service or information you have requested;<br/>
                                2) To process your order or request;<br/>
                                3) To send you updates and notifications about our products and services;<br/>
                                4) Improving our website and services;<br/>
                                5) Personalizing your user experience;<br/>
                                6) Fulfilling our legal obligations.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>3. Information Disclosure</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                We will disclose your personal information under the following circumstances:<br/>
                                1) With your express consent;<br/>
                                2) As required by law or as lawfully requested by government authorities;<br/>
                                3) To protect our legitimate interests or the safety of other users;<br/>
                                4) To share with our partners and third party service providers in order to provide the services you have requested.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>4. Information Protection</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                We take reasonable security measures to protect your personal information from unauthorized access, use, modification or disclosure.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>5. Cookies and Similar Technologies</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                Our website may use cookies and similar technologies to collect and store certain information.
                                You can manage cookie settings according to your preferences, but please note that disabling cookies
                                may affect your access to and experience with the features of the Site.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>6. Third Party Links</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                Our website may contain links to third party websites or services. We are not responsible for
                                the privacy practices or the content of these third party sites. Please read the privacy policies
                                of these sites before visiting them.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>7. Privacy Rights</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                In accordance with applicable law, you have the right to access, correct, delete and limit the
                                use of your personal information. To exercise these rights or to ask privacy-related questions,
                                please contact our Privacy Team.
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title style={{textDecoration: 'underline'}} level={4}>8. Changes to the Privacy Policy</Title>
                        </Col>
                        <Col span={24}>
                            <Paragraph style={{fontSize: "18px"}}>
                                We reserve the right to change this privacy policy at any time. For material changes, we will
                                notify you by posting an updated Privacy Policy on the Site.
                            </Paragraph>
                        </Col>
                    </Row>
                </Row>
            </div>
        )}
    </div>
  );
}

export default PrivacyPage;
